import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, useMixpanel, useTranslation } from 'hooks';
import { Button } from 'components/ui/button';
import { IFileWithMeta, StatusValue } from 'react-dropzone-uploader'
import { FileUploader } from 'components/common/_atoms/FileUploader';
import { MIXPANEL } from 'const';
import { IClientData, getDataRecordsList } from 'store/clientManagement';
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from 'react-hook-form';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from 'components/ui/dialog';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { selectMeetingTemplates } from 'store/templates-management';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import { IUploadInPersonRecordingData, uploadInPersonRecording } from 'store/chat-assistant';
import { selectProfileDetails } from 'store/profile';
import { toast } from 'react-toastify';
import { UploadStatusEnum, selectUploadStatus } from 'store/upload-management';
import { Input } from 'components/ui/input';

interface IProps {
  isVisible: boolean;
  setIsVisible: (visibility: boolean) => void;
  client: IClientData | null;
}

interface IFormData {
  file: string;
  title: string;
  template: string;
}

export const UploadAudioDataRecordModal = ({ isVisible, setIsVisible, client }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { trackEvent } = useMixpanel({ trackPageVisit: false });

  const profileDetails = useSelector(selectProfileDetails);
  const meetingTemplates = useSelector(selectMeetingTemplates);
  const uploadStatus = useSelector(selectUploadStatus);

  const [filesToUpload, setFilesToUpload] = useState<Array<IFileWithMeta>>([]);

  const formSchema = z.object({
    file: z.string().min(1, t(`component.UploadAudioDataRecordModal.audioFileRequired`) as string),
    title: z.string().min(1, t('errors.required') as string),
    template: z.string().optional(),
  });

  const form = useForm<IFormData>({
    resolver: zodResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: {
      file: '',
      title: '',
      template: '',
    }
  });

  useEffect(() => {
    if (!isVisible) {
      resetComponent();
    }
  }, [isVisible]);

  const handleLocalUpload = (
    file: IFileWithMeta,
    status: StatusValue,
    allFiles: IFileWithMeta[]
  ) => {

    const isIncomplete =
      allFiles.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status)) ||
      !allFiles.some(f => ['headers_received', 'ready', 'done'].includes(f.meta.status))

    form.setValue('file', !isIncomplete ? 'done' : '');
    form.trigger('file');

    setFilesToUpload(allFiles);
  }

  const handleUploadAudioDataRecord = async (values: IFormData) => {
    try {
      const formData = new FormData();
      filesToUpload?.forEach(item => {
        formData.append('file', item.file);
      })

      const activeTemplate = meetingTemplates?.find(template => template.id === values.template)
      const dataPointsPayload = activeTemplate?.dataPoints?.map(dataPoint => dataPoint.name) || [];
      const templateQuestions: string[] = [];

      activeTemplate?.sections?.forEach(section => {
        section?.questions?.forEach(question => {
          if (!!question.text) {
            templateQuestions.push(question.text)
          }
        })
      });

      const questionsPayload = [...templateQuestions]

      const uploadDataPayload: IUploadInPersonRecordingData = {
        title: values?.title || '',
        advisor_name: `${profileDetails?.firstName} ${profileDetails?.lastName}`,
        client_id: client?.id || '',
        data_points: dataPointsPayload,
        questions: questionsPayload
      }
      formData.append('data', JSON.stringify(uploadDataPayload) as any);

      const uploadResponse = await dispatch(uploadInPersonRecording(formData));


      if (!uploadResponse.hasOwnProperty('error')) {
        trackEvent({ action: MIXPANEL.ACTION.CLIENTS.UPLOAD_DATA_RECORD, type: MIXPANEL.TYPE.CLICKED })
        toast(t(`component.UploadAudioDataRecordModal.createSuccess`), { type: 'success' })
        setIsVisible(false);
        if (client) {
          setTimeout(() => {
            dispatch(getDataRecordsList(client.id))
          }, 2000);
        }
      }
    } catch (err) {
      console.error('Failed to upload file', err);
    }
  }

  const resetComponent = () => {
    form.reset();
  }

  return (
    <Fragment>
      {isVisible && (
        <Dialog open={isVisible} onOpenChange={open => setIsVisible(open)}>
          <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>
                {t(`component.UploadAudioDataRecordModal.title`)}
              </DialogTitle>
              <DialogDescription>
                <p>
                  {t(`component.UploadAudioDataRecordModal.description`)}
                </p>
              </DialogDescription>
            </DialogHeader>

            <Form {...form}>
              <form onSubmit={form.handleSubmit(handleUploadAudioDataRecord)} className="flex flex-col gap-6">

                <FormField
                  control={form.control}
                  name="file"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FileUploader
                        onChangeStatus={handleLocalUpload}
                        showSubmitButton={false}
                        accept="audio/*"
                        maxFiles={1}
                        multiple={false}
                        titleNoDocs={t(`component.UploadAudioDataRecordModal.uploadTitle`) as string}
                        descriptionNoDocs={t(`component.UploadAudioDataRecordModal.uploadDescription`) as string}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t(`component.UploadAudioDataRecordModal.titleLabel`)}
                      </FormLabel>
                      <FormLabel variant="description">
                        {t(`component.UploadAudioDataRecordModal.titleLabelDesc`)}
                      </FormLabel>

                      <FormControl>
                        <Input {...field}
                          autoComplete="off"
                          data-1p-ignore
                          disabled={[UploadStatusEnum.UPLOADING].includes(uploadStatus)} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {meetingTemplates && meetingTemplates?.length > 0 && (
                  <FormField
                    control={form.control}
                    name="template"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          {t('component.UploadAudioDataRecordModal.template')}
                        </FormLabel>
                        <FormLabel variant="description">
                          {t('component.UploadAudioDataRecordModal.templateDesc')}
                        </FormLabel>

                        <Select onValueChange={field.onChange} defaultValue={field.value} value={field.value}>
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder={t('component.UploadAudioDataRecordModal.templateSelect')} />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {meetingTemplates?.map(template => (
                              <SelectItem
                                key={template.id}
                                value={template.id}
                              >
                                {template.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}

                <DialogFooter>
                  <Button
                    type="submit"
                    loading={[UploadStatusEnum.UPLOADING].includes(uploadStatus)}
                  >
                    {t(`component.UploadAudioDataRecordModal.uploadBtn`)}
                  </Button>
                </DialogFooter>
              </form>
            </Form>
          </DialogContent>
        </Dialog >
      )}
    </Fragment >

  );
};
