import { processApiError } from "services";
import { api } from "services/api";
import { IOrganization, IOrganizationsPayload } from "./types";
import {
  PrepareAction,
  createAction,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { ACTION } from "const/actions";

export const getOrganizations = createAsyncThunk(
  ACTION.ORGANIZATIONS.GET_ORGANIZATIONS,
  async (payload: void, { rejectWithValue }) => {
    try {
      const response = await api.organizations.getOrganizations();
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const getOrganization = createAsyncThunk(
  ACTION.ORGANIZATIONS.GET_ORGANIZATION,
  async (payload: IOrganizationsPayload, { rejectWithValue }) => {
    try {
      const response = await api.organizations.getOrganization(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const createOrganization = createAsyncThunk(
  ACTION.ORGANIZATIONS.CREATE_ORGANIZATION,
  async (payload: IOrganization, { rejectWithValue }) => {
    try {
      const response = await api.organizations.createOrganization(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const updateOrganization = createAsyncThunk(
  ACTION.ORGANIZATIONS.UPDATE_ORGANIZATION,
  async (payload: IOrganization, { rejectWithValue }) => {
    try {
      const response = await api.organizations.updateOrganization(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const deleteOrganization = createAsyncThunk(
  ACTION.ORGANIZATIONS.DELETE_ORGANIZATION,
  async (payload: IOrganizationsPayload, { rejectWithValue }) => {
    try {
      const response = await api.organizations.deleteOrganization(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);
