import { useDevFlag, useTranslation } from 'hooks';
import { CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { Card, CardContent } from 'components/ui/card';
import { ConnectCalendar } from './ConnectCalendar';
import { ConnectRedtail } from './ConnectRedtail';

export const ManageConnections = () => {
  const { t } = useTranslation();
  const { isDevEnv } = useDevFlag()

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('component.ManageConnections.title')}</CardTitle>
        <CardDescription>
          {t('component.ManageConnections.description')}
        </CardDescription>
      </CardHeader>

      <CardContent className="flex flex-col gap-10">
        {isDevEnv && (
          <ConnectCalendar />
        )}
        <ConnectRedtail />
      </CardContent>
    </Card >
  );
};
