import { createAsyncThunk } from "@reduxjs/toolkit";
import { processApiError } from "services";
import { api } from "services/api";
import { ACTION } from "const/actions";
import { IMeetingTemplate, IMeetingTemplateFormData } from "./types";
import _ from "lodash";

export const getDataPointList = createAsyncThunk(
  ACTION.TEMPLATES_MANAGEMENT.GET_DATA_POINT_LIST,
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await api.templatesManagement.getDataPointList();
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const getTemplates = createAsyncThunk(
  ACTION.TEMPLATES_MANAGEMENT.GET_TEMPLATES,
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await api.templatesManagement.getTemplates();
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const createTemplate = createAsyncThunk(
  ACTION.TEMPLATES_MANAGEMENT.CREATE_TEMPLATE,
  async (payload: IMeetingTemplate, { rejectWithValue }) => {
    try {
      const response = await api.templatesManagement.createTemplate(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const updateTemplate = createAsyncThunk(
  ACTION.TEMPLATES_MANAGEMENT.UPDATE_TEMPLATE,
  async (payload: IMeetingTemplateFormData, { rejectWithValue }) => {
    try {
      const callPayload = _.omit(payload, [
        "formSelectedTemplateId",
        "formSelectedDataPointOptions",
      ]);
      const response = await api.templatesManagement.updateTemplate(
        callPayload
      );
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const deleteTemplate = createAsyncThunk(
  ACTION.TEMPLATES_MANAGEMENT.DELETE_TEMPLATE,
  async (payload: IMeetingTemplate, { rejectWithValue }) => {
    try {
      const response = await api.templatesManagement.deleteTemplate(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);
