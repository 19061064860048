import { IDeleteDataRecordPayload } from "store/clientManagement";

export const API_ROUTES = {
  mixpanel: "/mixpanel-proxy",
  authentication: {
    login: "/authentication/login",
    register: "/authentication/register",
    refresh: "/authentication/refresh",
    signUp: "/authentication/sign-up",
  },
  signUp: { create: "/sign-up/create" },
  profile: {
    getProfileDetails: "/profile/me",
    updatePreferences: "/profile/update-preferences",
  },
  users: {
    getUsersList: "/users",
    getUserDetails: (id: string) => `/users/${id}`,
    updateUser: (id: string) => `/users/${id}`,
    deleteUser: (id: string) => `/users/${id}`,
    getAdvisorsMailbox: () => `/users/advisors-mailbox`,
  },
  calendar: {
    getCalendarEvents: `/calendar/get-calendar-events`,
  },
  redtail: {
    connectRedtail: `/redtail/connect`,
    disconnectRedtail: `/redtail/disconnect`,
  },
  emails: {
    generateAuthUrl: "/nylas/generate-auth-url",
    exchangeMailboxToken: "/nylas/exchange-mailbox-token",
    generateCalendarAuthUrl: "/nylas-calendar/generate-auth-url",
    exchangeCalendarToken: "/nylas-calendar/exchange",
    deleteCalendarAccount: "/nylas-calendar/delete-calendar-account",
  },
  mailbox: {
    getThreadsList: "/mailbox/get-threads",
    searchThreadsList: "/mailbox/search-threads",
    getDraftsList: "/mailbox/get-drafts",
    getArchivedThreadsList: "/mailbox/get-archived-threads",
    getThreadsWithConversationList: "/mailbox/get-archived-threads",
    getThreadDetails: (id: string) => `/mailbox/get-thread/${id}`,
    getMessage: (id: string) => `/mailbox/get-message/${id}`,
    getEmailAttachment: (id: string) => `/mailbox/get-file/${id}`,
    uploadFiles: `/mailbox/upload-files`,
    deleteFile: (id: string) => `/mailbox/delete-file/${id}`,
    getDraft: (id: string) => `/mailbox/get-draft/${id}`,
    createDraft: `/mailbox/create-draft`,
    updateDraft: (id: string) => `/mailbox/update-draft/${id}`,
    deleteDraft: (id: string) => `/mailbox/delete-draft/${id}`,
    updateThreadLabel: (id: string) => `/mailbox/update-thread-label/${id}`,
    deleteThreadLabel: (id: string) => `/mailbox/delete-thread-label/${id}`,
  },
  notifications: {
    markAsRead: "/notifications/mark-as-read",
    getGeneralConversation: "/notifications/general-conversation",
  },
  chat: {
    getConversationId: (threadId: string) =>
      `/mailbox/get-thread-conversation/${threadId}`,
    uploadSecurelyFiles: `/secure-upload/upload-files`,
    createUploadSecurelyFilesRequest: "/secure-upload/create",
  },
  analysis: {
    uploadFiles: `/portfolio-analysis/upload-files`,
    createRequest: `/portfolio-analysis/create-request`,
    submitCredentials: `/portfolio-analysis/submit-credentials`,
    ohNo: `/portfolio-analysis/oh-no`,
  },
  credentials: {
    saveCredentials: `/credentials/save-credentials`,
  },
  portfolioModel: {
    updatePortfolioModels: "/portfolio-model/update",
    createPortfolioModels: "/portfolio-model/create",
    listPortfolioModels: "/portfolio-model/list",
    uploadPortfolioModels: "/portfolio-comparison/upload-files",
    uploadComparisonFiles: "/portfolio-comparison/upload-files",
    createComparisonRequest: "/portfolio-comparison/create-request",
  },
  dataExtractor: {
    createDataExtractorRequest: "/data-extraction/create-request",
  },
  automations: {
    createInvestmentStatementExtractionWithHumanCheckRequest:
      "/data-extraction/create-request-with-check",
  },
  chatAssistant: {
    createBot: "/call-assistant/create-bot",
    getTranscript: (botId: string) => `/call-assistant/get-transcript/${botId}`,
    getTranscriptAIAnswer: `/call-assistant/talk-to-transcript`,
    getPastDataAIAnswer: `/call-assistant/past-data-search`,
    getDataPointValue: (botId: string) =>
      `/call-assistant/get-data-point-value/${botId}`,
    getDataPointsStatus: (botId: string) =>
      `/call-assistant/get-data-points-status/${botId}`,
    getQuestionValue: (botId: string) =>
      `/call-assistant/get-question-value/${botId}`,
    getQuestionsStatus: (botId: string) =>
      `/call-assistant/get-data-questions-status/${botId}`,
    downloadTranscript: (botId: string) =>
      `/call-assistant/download-transcript/${botId}`,
    downloadVideo: (botId: string) => `/call-assistant/download-video/${botId}`,
    getPostCallSummary: (botId: string) =>
      `/call-assistant/post-call-summary/${botId}`,
    getHeadsUpDisplay: (botId: string) =>
      `/call-assistant/get-heads-up/${botId}`,
  },
  clientManagement: {
    getClientsList: `/client/list`,
    createClient: `/client/create`,
    deleteClient: (id: string) => `/client/${id}`,
    getClient: (id: string) => `/client/${id}`,
    createDataRecord: (clientId: string) => `/client/${clientId}/data-record`,
    linkGoogleDriveFolder: (clientId: string) =>
      `/client/${clientId}/add-gdrive-folder`,
    getDataRecordsList: (clientId: string) =>
      `/client/${clientId}/data-record/list`,
    deleteDataRecord: (payload: IDeleteDataRecordPayload) =>
      `/client/${payload.clientId}/data-record/${payload.recordId}`,
    getAiDataSearchAnswer: (clientId: string) =>
      `/client/${clientId}/data-search`,
    getAiDataSearchWhoIsClient: (clientId: string) =>
      `/client/${clientId}/summary`,
    getAiDataSearchDoAgenda: (clientId: string) => `/client/${clientId}/agenda`,
    syncSalesforce: (clientId: string) => `/client/${clientId}/sync-salesforce`,
    importRedtail: `/client/import-redtail`,
    syncRedtail: (clientId: string) => `/client/${clientId}/sync-redtail`,
  },
  templatesManagement: {
    getDataPointList: "/template/data-point-list",
    getTemplates: "/template/list",
    createTemplate: "/template/create",
    updateTemplate: (id: string) => `/template/${id}`,
    deleteTemplate: (id: string) => `/template/${id}`,
  },
  headsUpCards: {
    getCards: "/card/list",
    createCard: "/card/create",
    updateCard: (id: string) => `/card/${id}`,
    deleteCard: (id: string) => `/card/${id}`,
  },
  inPersonMeeting: {
    createMeeting: "/inperson-meeting/create",
    getTranscript: (botId: string) =>
      `/inperson-meeting/get-transcript/${botId}`,
    downloadTranscript: (botId: string) =>
      `/inperson-meeting/download-transcript/${botId}`,
    getDataPointValue: (botId: string) =>
      `/inperson-meeting/data-point/${botId}`,
    getQuestionAnswers: (botId: string) => `/inperson-meeting/answers/${botId}`,
    getPostCallSummary: (botId: string) =>
      `/inperson-meeting/post-call-summary/${botId}`,
    getTranscriptAIAnswer: `/inperson-meeting/talk-to-transcript`,
    uploadRecording: "/inperson-meeting/create",
    downloadAudio: (botId: string) =>
      `/inperson-meeting/download-audio/${botId}`,
  },
  organizations: {
    getOrganizations: "/organizations",
    createOrganization: "/organizations",
    getOrganization: (id: string) => `/organizations/${id}`,
    updateOrganization: (id: string) => `/organizations/${id}`,
    deleteOrganization: (id: string) => `/organizations/${id}`,
  },
};
