import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'hooks';
import { Input } from 'components/ui/input';
import { AgendaSections } from './AgendaSections';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from 'components/ui/card';
import MultipleSelector, { Option } from 'components/ui/multi-select';
import { IMeetingTemplate, IMeetingTemplateFormData, selectDataPointList } from 'store/templates-management';
import { InfoCard } from 'components/layout-atoms/InfoCard';
import { TemplateReadOnlyDisplay } from 'components/common/_atoms/TemplateReadOnlyDisplay';
import { useSelector } from 'react-redux';

interface IProps {
  form: UseFormReturn<IMeetingTemplateFormData>
  template: IMeetingTemplate
  inEditMode?: boolean
}

export const MeetingTemplate = ({ form, template, inEditMode }: IProps) => {
  const { t } = useTranslation();

  const dataPointList = useSelector(selectDataPointList);

  const [multiSelectOptions, setMultiSelectOptions] = useState<Option[]>([])

  useEffect(() => {
    setMultiSelectOptions(dataPointList?.map(dataPoint => {
      return { label: dataPoint.name, value: dataPoint.id }
    }))
  }, [dataPointList])

  useEffect(() => {
    if (inEditMode) {
      const formSelectedDataPoints = template?.dataPoints?.map(dataPoint => {
        return { label: dataPoint.name, value: dataPoint.id }
      })

      form.setValue('formSelectedDataPointOptions', formSelectedDataPoints)
    }
  }, [template, form, inEditMode])

  return (
    <>
      <Form {...form}>
        <form className='flex flex-col gap-6'>
          {!inEditMode ? (
            <TemplateReadOnlyDisplay template={template} className='flex flex-col gap-6' />
          ) : (
            <>
              <Card>
                <CardHeader className=''>
                  <CardTitle>
                    {t('component.MeetingTemplate.generalSettingsTitle')}
                  </CardTitle>
                  <CardDescription>
                    {t('component.MeetingTemplate.generalSettingsDescription')}
                  </CardDescription>
                </CardHeader>
                <CardContent className='flex flex-col gap-6'>
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          {t('component.MeetingTemplate.name')}
                        </FormLabel>
                        <FormControl>
                          <Input {...field} autoComplete="off" data-1p-ignore />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </CardContent>
              </Card>


              <Card>
                <CardHeader className=''>
                  <CardTitle>
                    {t('component.MeetingTemplate.agenda')}
                  </CardTitle>
                  <CardDescription>
                    {t('component.MeetingTemplate.agendaDescription')}
                  </CardDescription>
                </CardHeader>

                <CardContent className='flex flex-col gap-6'>
                  <AgendaSections form={form} />
                </CardContent>
              </Card>


              {multiSelectOptions?.length > 0 && (
                <Card>
                  <CardHeader>
                    <CardTitle>
                      {t('component.MeetingTemplate.dataPoints')}
                    </CardTitle>
                    <CardDescription>
                      {t('component.MeetingTemplate.dataPointsDescription')}
                    </CardDescription>
                  </CardHeader>

                  <CardContent className='flex flex-col gap-6'>
                    <FormField
                      control={form.control}
                      name="formSelectedDataPointOptions"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <MultipleSelector
                              value={field.value}
                              onChange={(option) => {
                                field.onChange(option)
                              }}
                              options={multiSelectOptions}
                              placeholder=""
                              emptyIndicator={
                                <InfoCard className='border-none shadow-none py-0' title={t('component.MeetingTemplate.dataPointsListEmpty')} />
                              }
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </CardContent>
                </Card>
              )}
            </>
          )}
        </form >
      </Form >
    </>
  );
}
