import { useTranslation } from 'hooks';
import { useEffect, useState } from 'react';
import { IDynamicListItem } from 'store/chat-assistant';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, } from "components/ui/table"
import { SectionContainer } from 'components/common/_atoms/SectionContainer';
import { EditableField } from 'components/common/_atoms/EditableField';
import { Label } from 'components/ui/label';
import { Switch } from 'components/ui/switch';
import { InfoCard } from 'components/layout-atoms/InfoCard';

interface IProps {
  dataPoints: IDynamicListItem[]
  onDataPointsChange: (payload: IDynamicListItem[]) => void
}

interface IInputChangeFn {
  dataPoint: IDynamicListItem
  field: string
  value: string | boolean | undefined
}

export const DataPointsTable = ({ dataPoints, onDataPointsChange }: IProps) => {
  const { t } = useTranslation();

  const [editedDataPoints, setEditedDataPoints] = useState(dataPoints);

  useEffect(() => {
    setEditedDataPoints(dataPoints)
  }, [dataPoints])

  useEffect(() => {
    onDataPointsChange(editedDataPoints)
  }, [editedDataPoints, onDataPointsChange])

  const handleInputChange = ({ dataPoint, field, value }: IInputChangeFn) => {
    const updatedDataPoints = [...editedDataPoints]?.map(currentEntry => {
      if (currentEntry?.id !== dataPoint?.id) {
        return currentEntry;
      } else {
        return { ...currentEntry, [field]: value };
      }
    });

    setEditedDataPoints(updatedDataPoints);
  };

  return (
    <>
      <SectionContainer
        title={t('component.DataPointsTable.title') as string}
        description={t('component.DataPointsTable.description') as string}
      >
        {!dataPoints || dataPoints?.length === 0 ? (
          <InfoCard
            title={t('component.DataPointsTable.noData')}
          />
        ) : (
          <div className="rounded-md border">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className='w-64'>
                    {t('component.DataPointsTable.column.dataPoint')}
                  </TableHead>
                  {/* <TableHead>
                    {t('component.DataPointsTable.column.crmValue')}
                  </TableHead> */}
                  <TableHead>
                    {t('component.DataPointsTable.column.collectedValue')}
                  </TableHead>
                  <TableHead className='w-20'>
                    {t('component.DataPointsTable.column.sync')}
                  </TableHead>
                </TableRow>
              </TableHeader>

              <TableBody>
                {editedDataPoints.map((dataPoint) => (
                  <TableRow key={dataPoint.id}>
                    <TableCell>
                      <div className='px-3 py-1 text-sm text-muted-foreground'>
                        {dataPoint.text}
                      </div>
                    </TableCell>
                    <TableCell>
                      <EditableField
                        value={dataPoint.value}
                        onSave={({ newValue }) => {
                          handleInputChange({ dataPoint, field: 'value', value: newValue })
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Label className='px-3'>
                        <Switch
                          // checked={dataPoint.sync}
                          checked={false}
                          onCheckedChange={(checked) => {
                            handleInputChange({ dataPoint, field: 'sync', value: checked })
                          }}
                          disabled={true}
                        />
                      </Label>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </SectionContainer>
    </>
  );
};

