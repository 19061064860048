import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const getTemplatesManagementState = (state: RootState) =>
  state.templatesManagement;

export const selectIsGettingTemplates = createSelector(
  [getTemplatesManagementState],
  ({ isGettingTemplates }) => isGettingTemplates
);

export const selectMeetingTemplates = createSelector(
  [getTemplatesManagementState],
  ({ meetingTemplates }) => meetingTemplates
);

export const selectActiveTemplate = createSelector(
  [getTemplatesManagementState],
  ({ activeTemplate }) => activeTemplate
);

export const selectIsGettingDataPointList = createSelector(
  [getTemplatesManagementState],
  ({ isGettingDataPointList }) => isGettingDataPointList
);

export const selectDataPointList = createSelector(
  [getTemplatesManagementState],
  ({ dataPointsList }) => dataPointsList
);

export const selectIsCreatingTemplate = createSelector(
  [getTemplatesManagementState],
  ({ isCreatingTemplate }) => isCreatingTemplate
);

export const selectIsUpdatingTemplate = createSelector(
  [getTemplatesManagementState],
  ({ isUpdatingTemplate }) => isUpdatingTemplate
);

export const selectIsDeletingTemplate = createSelector(
  [getTemplatesManagementState],
  ({ isDeletingTemplate }) => isDeletingTemplate
);
