import { useMixpanel } from 'hooks';
import { GeneralLayout } from 'components/layout/GeneralLayout';
import { MuninRecorder } from 'components/common/_atoms/MuninRecorder';

export const InPersonMeetingPage = () => {
  const { trackEvent } = useMixpanel();

  return (
    <GeneralLayout hideMenu={true} contentClassName="items-center">
      <MuninRecorder />
    </GeneralLayout>
  );
};

