import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "store";

const getMyProfileState = (state: RootState) => state.myProfile;

export const selectProfileDetailsIsLoading = createSelector(
  [getMyProfileState],
  ({ loading }) => loading
);

export const selectProfileDetails = createSelector(
  [getMyProfileState],
  ({ profileDetails }) => profileDetails
);

export const selectProfileDetailsFullName = createSelector(
  [getMyProfileState],
  ({ profileDetails }) =>
    `${profileDetails?.firstName} ${profileDetails?.lastName}`
);

export const selectProfileRole = createSelector(
  [getMyProfileState],
  ({ profileDetails }) => profileDetails.role
);

export const selectProfilePreferences = createSelector(
  [getMyProfileState],
  ({ profileDetails }) => profileDetails.preferences
);

export const selectProfileHasConnectedRedtail = createSelector(
  [getMyProfileState],
  ({ profileDetails }) => !!profileDetails?.redtailCRM
);
