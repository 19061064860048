import { API_ROUTES } from "services/api/constants";
import { http } from "services/http";

import { IAuthenticationApi } from "./types";

export const authentication: IAuthenticationApi = {
  login: (payload) =>
    http
      .post(API_ROUTES.authentication.login, payload)
      .then((response) => response.data),
  register: (payload) =>
    http
      .post(API_ROUTES.authentication.register, payload)
      .then((response) => response.data),
  refresh: (payload) =>
    http
      .get(API_ROUTES.authentication.refresh, {
        headers: {
          Authorization: `Bearer ${payload.refresh_token}`,
        },
      })
      .then((response) => response.data),
  signUp: (payload) =>
    http
      .post(API_ROUTES.authentication.signUp, payload)
      .then((response) => response.data),
};
