import { http } from "services/http";
import { IUser } from "store/users";
import { API_ROUTES } from "../constants";
import { IUsersApi } from "./types";

export const users: IUsersApi = {
  getUsers: (payload) =>
    http
      .get(API_ROUTES.users.getUsersList, payload)
      .then((response) => response.data),
  getAdvisorMailboxes: (payload) =>
    http
      .get(API_ROUTES.users.getAdvisorsMailbox(), payload)
      .then((response) => response.data),
  signUp: (payload) =>
    http
      .post(API_ROUTES.signUp.create, payload)
      .then((response) => response.data),
  getUsersList: () =>
    http.get(API_ROUTES.users.getUsersList).then((response) => response.data),
  getUserDetails: (payload) =>
    http
      .get(API_ROUTES.users.getUserDetails(payload.id))
      .then((response) => response.data),
  updateUser: (payload: IUser) =>
    http
      .put(API_ROUTES.users.updateUser(payload.id), payload)
      .then((response) => response.data),
  deleteUser: (payload: IUser) =>
    http
      .delete(API_ROUTES.users.deleteUser(payload.id))
      .then((response) => response.data),
};
  