import { Option } from "components/ui/multi-select";
import { z } from "zod";
import { t } from "i18next";

export interface ITemplatesState {
  activeTemplate: IMeetingTemplate | undefined;
  meetingTemplates: IMeetingTemplate[];
  dataPointsList: IMeetingDataPoint[];
  isGettingDataPointList: boolean;
  isGettingTemplates: boolean;
  isCreatingTemplate: boolean;
  isUpdatingTemplate: boolean;
  isDeletingTemplate: boolean;
}

export interface IMeetingTemplate {
  id: string;
  name: string;
  sections: IMeetingQuestionsSection[];
  dataPoints: IMeetingDataPoint[];
  dataPointIds: string[];
}

export interface IMeetingTemplateFormData extends IMeetingTemplate {
  formSelectedTemplateId: string;
  formSelectedDataPointOptions: Option[];
}

export interface IMeetingQuestionsSection {
  id?: string;
  name: string;
  questions: IMeetingQuestionItem[];
}

export interface IMeetingQuestionItem {
  id?: string;
  text: string;
}

export interface IMeetingDataPoint {
  id: string;
  name: string;
}

const questionItemSchema = z.object({
  id: z.string().optional(),
  text: z.string().min(1, "Question text is required"),
});

const questionsSectionSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, t("errors.required") as string),
  questions: z.array(questionItemSchema).optional(),
});

export const templateFormSchema = z.object({
  name: z.string().min(1, t("errors.required") as string),
  sections: z
    .array(questionsSectionSchema)
    .optional()
    .refine(
      (sections) => {
        if (!sections) return true;
        return sections.every((section) => {
          if (!section.questions || section.questions.length === 0) return true;
          return section.name.length > 0;
        });
      },
      {
        message: t("errors.required") as string,
        path: ["sections"],
      }
    ),
});
