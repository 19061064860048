import { useDevFlag, useTranslation } from 'hooks';
import { ROUTES } from 'routing/constants';
import { SideMenuSubLayout } from 'components/layout/SideMenuSubLayout';

export const SettingsPage = () => {
  const { t } = useTranslation();
  const { isDevEnv } = useDevFlag()

  return (
    <SideMenuSubLayout
      menuItems={[
        {
          label: t("page.SettingsPage.templates"),
          to: ROUTES.APP.SETTINGS.TEMPLATES,
        },
        {
          label: t("page.SettingsPage.credentials"),
          to: ROUTES.APP.SETTINGS.CREDENTIALS,
        },
        {
          label: t("page.SettingsPage.connections"),
          to: ROUTES.APP.SETTINGS.CONNECTIONS,
          isComingSoon: !isDevEnv
        },
        {
          label: t("page.SettingsPage.headsUpDisplay"),
          to: ROUTES.APP.SETTINGS.CARDS,
          isComingSoon: !isDevEnv
        },
        {
          label: t("page.SettingsPage.compliance"),
          to: ROUTES.APP.SETTINGS.COMPLIANCE,
          isComingSoon: !isDevEnv
        },
        {
          label: t("page.SettingsPage.integrations"),
          to: ROUTES.APP.SETTINGS.INTEGRATIONS,
          isComingSoon: !isDevEnv
        },
        // {
        //   label: t("page.SettingsPage.security"),
        //   to: ROUTES.APP.SETTINGS.SECURITY,
        //   isComingSoon: true
        // },
        // {
        //   label: t("page.SettingsPage.support"),
        //   to: ROUTES.APP.SETTINGS.SUPPORT,
        //   isComingSoon: true
        // },
        // {
        //   label: t("page.SettingsPage.advanced"),
        //   to: ROUTES.APP.SETTINGS.ADVANCED,
        //   isComingSoon: true
        // },
      ]}
    />
  );
};

