import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import {
  IMeetingTemplate,
  ITemplatesState,
} from "./types";
import {
  createTemplate,
  deleteTemplate,
  getDataPointList,
  getTemplates,
  updateTemplate,
} from "./actions";

const initialState: ITemplatesState = {
  meetingTemplates: [],
  activeTemplate: undefined,
  dataPointsList: [],
  isGettingDataPointList: false,
  isGettingTemplates: false,
  isCreatingTemplate: false,
  isUpdatingTemplate: false,
  isDeletingTemplate: false,
};

const templatesManagementSlice = createSlice({
  name: "templatesManagement",
  initialState,
  reducers: {
    setActiveTemplate: (
      state,
      action: PayloadAction<IMeetingTemplate | undefined>
    ) => {
      state.activeTemplate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDataPointList.pending, (state, action) => {
        state.isGettingDataPointList = true;
      })
      .addCase(getDataPointList.fulfilled, (state, action) => {
        state.isGettingDataPointList = false;
        state.dataPointsList = action.payload;
      })
      .addCase(getDataPointList.rejected, (state) => {
        state.isGettingDataPointList = false;
      })
      .addCase(getTemplates.pending, (state, action) => {
        state.isGettingTemplates = true;
      })
      .addCase(getTemplates.fulfilled, (state, action) => {
        state.isGettingTemplates = false;
        state.meetingTemplates = action.payload?.sort((a, b) =>
          a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase())
        );
      })
      .addCase(getTemplates.rejected, (state) => {
        state.isGettingTemplates = false;
      })
      .addCase(createTemplate.pending, (state, action) => {
        state.isCreatingTemplate = true;
      })
      .addCase(createTemplate.fulfilled, (state, action) => {
        return {
          ...state,
          isCreatingTemplate: false,
          meetingTemplates: [...state.meetingTemplates, action.payload]?.sort((a, b) =>
            a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase())
          ),
          activeTemplate: action.payload,
        };
      })
      .addCase(createTemplate.rejected, (state) => {
        state.isCreatingTemplate = false;
      })
      .addCase(updateTemplate.pending, (state, action) => {
        state.isUpdatingTemplate = true;
      })
      .addCase(updateTemplate.fulfilled, (state, action) => {
        return {
          ...state,
          isUpdatingTemplate: false,
          meetingTemplates: state.meetingTemplates.map((template) =>
            template.id === action.payload.id ? action.payload : template
          )?.sort((a, b) =>
            a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase())
          ),
          activeTemplate: action.payload,
        };
      })
      .addCase(updateTemplate.rejected, (state) => {
        state.isUpdatingTemplate = false;
      })
      .addCase(deleteTemplate.pending, (state, action) => {
        state.isDeletingTemplate = true;
      })
      .addCase(deleteTemplate.fulfilled, (state, action) => {
        const currentState = current(state);

        const deletedTemplate = action?.meta.arg;
        return {
          ...state,
          isDeletingTemplate: false,
          meetingTemplates: state.meetingTemplates.filter(
            (template) => template.id !== deletedTemplate?.id
          ),
          activeTemplate:
            currentState?.activeTemplate?.id === deletedTemplate?.id
              ? undefined
              : currentState?.activeTemplate,
        };
      })
      .addCase(deleteTemplate.rejected, (state) => {
        state.isDeletingTemplate = false;
      });
  },
});

export const { setActiveTemplate } = templatesManagementSlice.actions;

export default templatesManagementSlice.reducer;
