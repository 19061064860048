import { http } from "services/http";
import { API_ROUTES } from "../constants";
import { ITemplatesManagementApi } from "./types";

export const templatesManagement: ITemplatesManagementApi = {
  getDataPointList: (_) =>
    http
      .get(API_ROUTES.templatesManagement.getDataPointList)
      .then((response) => response.data),
  getTemplates: (_) =>
    http
      .get(API_ROUTES.templatesManagement.getTemplates)
      .then((response) => response.data),
  createTemplate: (payload) =>
    http
      .post(API_ROUTES.templatesManagement.createTemplate, payload)
      .then((response) => response.data),
  updateTemplate: (payload) =>
    http
      .put(API_ROUTES.templatesManagement.updateTemplate(payload.id), payload)
      .then((response) => response.data),
  deleteTemplate: (payload) =>
    http
      .delete(API_ROUTES.templatesManagement.deleteTemplate(payload.id))
      .then((response) => response.data),
};
