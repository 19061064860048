import { IClientDataRecord } from "store/clientManagement";

export interface IChatAssistantState {
  botName: string;
  meetingLink: string;
  clientId: string;
  isCreateBotProcessing: boolean;
  ongoingCallBotId: string;
  pastDataSummary: string;
  isGetTranscriptProcessing: boolean;
  isGetTranscriptError: boolean;
  isTranscriptTruncated: boolean;
  transcript: ITranscriptItem[];
  fullTranscript: ITranscriptItem[];
  transcriptLastWord: ITranscriptWord | null;
  transcriptLastWordTS: number;
  isTranscriptStale: boolean;
  isGetTranscriptAIAnswerProcessing: boolean;
  transcriptAIAnswers: IGetAIAnswerResponse[];
  isGetPastDataAIAnswerProcessing: boolean;
  pastDataAIAnswers: IGetAIAnswerResponse[];

  isGetDataPointsStatusProcessing: boolean;
  isGetDataPointValueProcessing: boolean;
  dataPoints: IDynamicListItem[];
  dataPointValueFulfilledForIds: string[];
  dataPointsStatus: IDynamicListStatusItem[];

  isGetQuestionsStatusProcessing: boolean;
  isGetQuestionValueProcessing: boolean;
  questions: IDynamicListItem[];
  questionValueFulfilledForIds: string[];
  questionsStatus: IDynamicListStatusItem[];

  isGetTranscriptVideoUrlProcessing: boolean;
  videoUrl: string;
  isDownloadingVideo: boolean;
  downloadVideoBlobObjectUrl: string;
  
  isGetTranscriptAudioUrlProcessing: boolean;
  audioUrl: string;
  isDownloadingAudio: boolean;
  downloadAudioBlobObjectUrl: string;
  
  isDownloadingTranscript: boolean;
  downloadTranscriptBlobObjectUrl: string;
  isGetPostCallSummaryProcessing: boolean;
  postCallSummary: IGetPostCallSummaryResponse;
  isGetHeadsUpDisplayProcessing: boolean;
  headsUpDisplayItems: IHeadsUpDisplayItem[];

  savedCopilotConfigurationFormValues: IConfigureCopilotMeetingForm | null;
  savedCopilotConfigurationMeetingType: CopilotMeetingType | null;

  inPersonRecordingData: IUploadInPersonRecordingData | null;
}

export enum MEETING_TYPE {
  IN_PERSON,
  WEB,
}

export interface ITranscriptItem {
  words: ITranscriptWord[];
  source: string | null;
  speaker: string;
  is_final?: boolean;
  language?: string | null;
  original_transcript_id?: number;
}

export interface IProcessedWord extends ITranscriptWord {
  is_final?: boolean;
  original_transcript_id?: number;
}

export interface IProcessedTranscriptItem {
  phrases: Array<IProcessedWord[]>;
  speaker: string;
  startTime: number;
}

export interface ITranscriptWord {
  text: string;
  end_time: number;
  start_time: number;
}

export interface ICreateBotPayload {
  bot_name: string;
  meeting_link: string;
  advisor_name: string;
  client_id: string;
  past_data: string;
  data_points: string[];
  questions: string[];
}

export interface ICreateBotResponse {
  botId: string;
  summary: string;
  data_points: ICreateBotDataPoint[];
  questions: ICreateBotDataPoint[];
}

export interface ICreateBotDataPoint {
  id: string;
  title: string;
}

export interface IGetTranscriptResponse {
  transcript: ITranscriptItem[];
  call_ended: boolean;
}

export interface IGetAIAnswerPayload {
  botId: string;
  question: string;
  clientId: string;
  dataRecord?: IClientDataRecord;
}

export interface IGetAIAnswerResponse {
  botId?: string;
  clientId?: string;
  question: string;
  answer: string;
  // aiAnswerType?: AiAnswerType;
}

export interface IGetDataPointsStatusPayload {
  botId: string;
  timestamp?: number;
}

export interface IGetDataPointValuePayload
  extends IGetChatAssistantCommonPayload {
  task_ids: string[];
}

export interface IGetQuestionValuePayload
  extends IGetChatAssistantCommonPayload {
  task_ids: string[];
}

export interface IDynamicListItem {
  id: string;
  text: string;
  value: string;
  sync?: boolean;
}

export interface IDynamicListStatusItem {
  id: string;
  question: string;
  answer_in_progress: boolean;
}

export interface IDownloadMediaPayload {
  botId: string;
  dataRecord: IClientDataRecord;
}

export interface IDownloadVideoResponse {
  video_url: string;
}

export interface IDownloadAudioResponse {
  audio_url: string;
}

export interface IGetPostCallSummaryResponse {
  summary: string;
  action_items: string;
  follow_up_mail: string;
}

export interface IHeadsUpDisplayItem {
  createdAt: string;
  id: string;
  recallBotId: string;
  title: string;
  description: string;
  updatedAt: string;
  userId: string;
}

export interface IConfigureCopilotMeetingForm
  extends Pick<
    ICreateBotPayload,
    "bot_name" | "meeting_link" | "advisor_name" | "client_id" | "past_data"
  > {
  data_points: string;
  questions: string;
  extra_questions: string;
  call_template: string;
}

export interface IGetChatAssistantCommonPayload {
  botId: string;
  dataRecord: IClientDataRecord;
}

export interface IGetInPersonTranscriptResponse {
  transcript: string;
}

export interface IUploadInPersonRecordingData {
  advisor_name: string;
  client_id: string;
  data_points: string[];
  questions: string[];
  title?: string;
}

export interface ICrmTask {
  due_date: string;
  title: string;
  description: string;
  assignee: string;
  sync: boolean;
}

export interface IMeetingNotesSyncPicker {
  dataPoints: boolean;
  questions: boolean;
  summary: boolean;
  actionItems: boolean;
}

export const enum CopilotMeetingType {
  ONLINE = "online",
  IN_PERSON = "inPerson",
}
