import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "store";

const getChatAssistantState = (state: RootState) => state.chatAssistant;

export const selectMeetingLink = createSelector(
  [getChatAssistantState],
  ({ meetingLink }) => meetingLink
);

export const selectBotName = createSelector(
  [getChatAssistantState],
  ({ botName }) => botName
);

export const selectClientId = createSelector(
  [getChatAssistantState],
  ({ clientId }) => clientId
);

export const selectIsCreateBotProcessing = createSelector(
  [getChatAssistantState],
  ({ isCreateBotProcessing }) => isCreateBotProcessing
);

export const selectPastDataSummary = createSelector(
  [getChatAssistantState],
  ({ pastDataSummary }) => pastDataSummary
);

export const selectIsGetTranscriptProcessing = createSelector(
  [getChatAssistantState],
  ({ isGetTranscriptProcessing }) => isGetTranscriptProcessing
);

export const selectIsGetTranscriptError = createSelector(
  [getChatAssistantState],
  ({ isGetTranscriptError }) => isGetTranscriptError
);

export const selectTranscript = createSelector(
  [getChatAssistantState],
  ({ transcript }) => transcript
);

export const selectFullTranscript = createSelector(
  [getChatAssistantState],
  ({ fullTranscript }) => fullTranscript
);

export const selectIsTranscriptStale = createSelector(
  [getChatAssistantState],
  ({ isTranscriptStale }) => isTranscriptStale
);

// This gets to null when call ends
export const selectOngoingCallBotId = createSelector(
  [getChatAssistantState],
  ({ ongoingCallBotId }) => ongoingCallBotId
);

export const selectIsTranscriptTruncated = createSelector(
  [getChatAssistantState],
  ({ isTranscriptTruncated }) => isTranscriptTruncated
);

export const selectIsGetTranscriptAIAnswerProcessing = createSelector(
  [getChatAssistantState],
  ({ isGetTranscriptAIAnswerProcessing }) => isGetTranscriptAIAnswerProcessing
);

export const selectTranscriptAIAnswers = createSelector(
  [getChatAssistantState],
  ({ transcriptAIAnswers }) => transcriptAIAnswers
);

export const selectIsGetPastDataAIAnswerProcessing = createSelector(
  [getChatAssistantState],
  ({ isGetPastDataAIAnswerProcessing }) => isGetPastDataAIAnswerProcessing
);

export const selectPastDataAIAnswers = createSelector(
  [getChatAssistantState],
  ({ pastDataAIAnswers }) => pastDataAIAnswers
);

// DATA POINTS
export const selectDataPoints = createSelector(
  [getChatAssistantState],
  ({ dataPoints }) => dataPoints
);

export const selectIsGetDataPointValueProcessing = createSelector(
  [getChatAssistantState],
  ({ isGetDataPointValueProcessing }) => isGetDataPointValueProcessing
);

export const selectDataPointValueFulfilledForIds = createSelector(
  [getChatAssistantState],
  ({ dataPointValueFulfilledForIds }) => dataPointValueFulfilledForIds
);

export const selectDataPointsStatus = createSelector(
  [getChatAssistantState],
  ({ dataPointsStatus }) => dataPointsStatus
);

// QUESTIONS
export const selectQuestions = createSelector(
  [getChatAssistantState],
  ({ questions }) => questions
);

export const selectIsGetQuestionValueProcessing = createSelector(
  [getChatAssistantState],
  ({ isGetQuestionValueProcessing }) => isGetQuestionValueProcessing
);

export const selectQuestionValueFulfilledForIds = createSelector(
  [getChatAssistantState],
  ({ questionValueFulfilledForIds }) => questionValueFulfilledForIds
);

export const selectQuestionsStatus = createSelector(
  [getChatAssistantState],
  ({ questionsStatus }) => questionsStatus
);

// -----

export const selectIsDownloadingTranscript = createSelector(
  [getChatAssistantState],
  ({ isDownloadingTranscript }) => isDownloadingTranscript
);

export const selectIsDownloadingVideo = createSelector(
  [getChatAssistantState],
  ({ isDownloadingVideo }) => isDownloadingVideo
);

export const selectIsDownloadingAudio = createSelector(
  [getChatAssistantState],
  ({ isDownloadingAudio }) => isDownloadingAudio
);

export const selectPostCallSummary = createSelector(
  [getChatAssistantState],
  ({ postCallSummary }) => postCallSummary
);

export const selectIsGetPostCallSummaryProcessing = createSelector(
  [getChatAssistantState],
  ({ isGetPostCallSummaryProcessing }) => isGetPostCallSummaryProcessing
);

export const selectIsGetTranscriptVideoUrlProcessing = createSelector(
  [getChatAssistantState],
  ({ isGetTranscriptVideoUrlProcessing }) => isGetTranscriptVideoUrlProcessing
);

export const selectVideoUrl = createSelector(
  [getChatAssistantState],
  ({ videoUrl }) => videoUrl
);

export const selectAudioUrl = createSelector(
  [getChatAssistantState],
  ({ audioUrl }) => audioUrl
);

export const selectIsGetTranscriptAudioUrlProcessing = createSelector(
  [getChatAssistantState],
  ({ isGetTranscriptAudioUrlProcessing }) => isGetTranscriptAudioUrlProcessing
);

export const selectHeadsUpDisplayItems = createSelector(
  [getChatAssistantState],
  ({ headsUpDisplayItems }) => headsUpDisplayItems
);

export const selectHeadsUpDisplayItemsCount = createSelector(
  [getChatAssistantState],
  ({ headsUpDisplayItems }) => headsUpDisplayItems?.length
);

export const selectSavedCopilotConfigurationFormValues = createSelector(
  [getChatAssistantState],
  ({ savedCopilotConfigurationFormValues }) =>
    savedCopilotConfigurationFormValues
);

export const selectSavedCopilotConfigurationMeetingType = createSelector(
  [getChatAssistantState],
  ({ savedCopilotConfigurationMeetingType }) =>
    savedCopilotConfigurationMeetingType
);

export const selectInPersonRecordingData = createSelector(
  [getChatAssistantState],
  ({ inPersonRecordingData }) => inPersonRecordingData
);
